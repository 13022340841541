.faq-popover .MuiPopover-paper {
  bottom: 20px;
  right: 20px;
}

.shop-page-dialog .MuiPaper-rounded {
  border-radius: 0px !important;
}

.shop-page-dialog .MuiDialog-paperWidthSm {
  max-width: 850px !important;
}

.shop-page-dialog .MuiBackdrop-root {
  background: none !important;
}

.dialog-wrapper {
  position: relative;
  text-align: center;
  padding: 20px;
  text-align: center;
  background: #8a8c8f;
  color: white;
}

.dialog-wrapper .close {
  position: absolute;
  bottom: 48px;
  right: 16px;
  color: black;
}

.dialog-wrapper .close button {
  padding: 0;
}

.dialog-wrapper .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
}

.dialog-wrapper .button-container .sunny-logo,
.dialog-wrapper .button-container .jane-logo {
  margin: 0 auto 25px;
  display: block;
  border-radius: 10px;
}

.dialog-wrapper .button-container .sunny-logo {
  width: 150px;
}

.dialog-wrapper .button-container .jane-logo {
  width: 86px;
}

.dialog-wrapper .button-container .shop-sunny {
  border-bottom: 1px solid white;
  padding-bottom: 35px;
  margin-bottom: 32px;
}

.dialog-wrapper .button-container a {
  text-decoration: none;
}

.dialog-wrapper .button-container a button {
  font-size: 12px;
  background: none;
  color: #8a8c8f;
  background: white;
  -webkit-transition: 200ms ease;
  transition: 200ms ease;
}

.dialog-wrapper .button-container a button:hover {
  background: #58595b;
  color: white;
}

.dialog-wrapper .logo-container {
  margin-bottom: 40px;
  padding: 15px;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.dialog-wrapper .logo-container svg,
.dialog-wrapper .logo-container img {
  max-width: 120px;
  margin: auto;
}

.dialog-wrapper .content {
  margin: 95px auto 10px;
  max-width: 450px;
}

.dialog-wrapper .content p {
  font-size: 16px;
}

.dialog-wrapper .disclaimer-text p:first-of-type {
  margin: 35px auto 0 auto;
}

.dialog-wrapper .disclaimer-text p {
  font-size: 12px;
  line-height: 16px;
  max-width: 500px;
  margin: 20px auto 0 auto;
}

.dialog-wrapper .disclaimer-text p a {
  text-decoration: underline;
}

@media only screen and (min-width: 900px) {
  .dialog-wrapper .button-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 30px;
  }
  .dialog-wrapper .button-container .sunny-logo,
  .dialog-wrapper .button-container .jane-logo {
    display: block;
  }
  .dialog-wrapper .button-container .sunny-logo {
    width: 198px;
    margin-bottom: 35px;
  }
  .dialog-wrapper .button-container .jane-logo {
    width: 104px;
  }
  .dialog-wrapper .button-container .shop-sunny {
    border-right: 1px solid white;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    padding: 15px 35px 0 35px;
  }
  .dialog-wrapper .button-container .shop-jane {
    padding: 15px 35px 0 35px;
  }
  .dialog-wrapper .button-container a button {
    font-size: 16px;
  }
  .dialog-wrapper .logo-container {
    margin-bottom: 20px;
  }
  .dialog-wrapper .logo-container svg {
    max-width: 160px;
  }
  .dialog-wrapper .logo-container .close {
    bottom: 44px;
  }
  .dialog-wrapper .content {
    margin-bottom: 50px;
    margin-top: 110px;
  }
  .dialog-wrapper .content p {
    font-size: 20px;
  }
  .dialog-wrapper .disclaimer-text {
    margin-top: 50px;
  }
}

@font-face {
  font-family: "Averta Regular";
  src: url("../fonts/Averta-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Averta Bold";
  font-weight: bold;
  src: url("../fonts/Averta-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Domain Display";
  font-weight: normal;
  src: url("../fonts/DomaineDisplay-Semibold.otf") format("opentype");
}

.mr-10 {
  margin-right: 10px;
}

.yellow {
  color: #ffcf00;
}

.blue {
  color: #009fd9;
}

.red {
  color: #ce0e2d;
}

.rose {
  color: #f48793;
}

.violet {
  color: #b6b8dd;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Averta Regular";
  letter-spacing: 2px;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  overflow: auto;
  overflow: hidden;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 10px !important;
}

.mr-10 {
  margin-right: 20px !important;
}

.mr-15 {
  margin-right: 30px !important;
}

.mr-20 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 10px !important;
}

.ml-10 {
  margin-left: 20px !important;
}

.ml-15 {
  margin-left: 30px !important;
}

.ml-20 {
  margin-left: 40px !important;
}

.center-align {
  text-align: left !important;
}

.center-align {
  text-align: center !important;
}

.right-align {
  text-align: right !important;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

input,
textarea,
select {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 0 !important;
}

input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

img {
  display: block;
}

h1 {
  margin: 0;
  white-space: pre-wrap;
  font-family: "Averta Bold";
  font-size: 36px;
  letter-spacing: 3.6px;
}

h2 {
  margin: 0;
  font-family: "Averta Bold";
  font-size: 28px;
}

h3 {
  margin: 0;
  font-family: "Averta Bold";
  font-size: 20px;
  letter-spacing: 2px;
}

h6 {
  margin: 0;
  color: #8a8c8f;
  font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 20px;
}

p {
  margin: 0;
  font-size: 16px;
  letter-spacing: 1.6px;
}

img {
  display: block;
}

.gray-headlines-and-copy h1,
.gray-headlines-and-copy h2,
.gray-headlines-and-copy h3,
.gray-headlines-and-copy h4,
.gray-headlines-and-copy h5 {
  color: #58595b;
}

.gray-headlines-and-copy p,
.gray-headlines-and-copy a:not(.button),
.gray-headlines-and-copy ul,
.gray-headlines-and-copy li,
.gray-headlines-and-copy ol {
  color: #8a8c8f;
}

.gray-headlines-white-button-copy {
  color: #58595b;
}

.gray-headlines-white-button-copy a {
  color: white;
}

ul {
  text-align: left !important;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 18px;
  }
}

.button {
  display: inline-block !important;
  -webkit-appearance: none !important;
  font-size: 16px !important;
  padding: 8px 40px !important;
  color: white;
  background: #8a8c8f;
  border: none !important;
  outline: none !important;
  text-decoration: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  white-space: nowrap !important;
  border-radius: 6px !important;
  text-transform: unset !important;
  cursor: pointer;
  font-family: "Averta Bold" !important;
}

.button:hover {
  color: white !important;
  background: #707276 !important;
  cursor: pointer;
}

#age-gate {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px;
}

#age-gate .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

#age-gate .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#age-gate .background-video video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

#age-gate .age-gate-content {
  background: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 4px;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  z-index: 3;
  max-height: 600px;
  -ms-flex-item-align: center;
      align-self: center;
  text-align: center;
  position: relative;
}

#age-gate .age-gate-content .spanish-toggle {
  position: absolute;
  top: 0;
  right: 0;
}

#age-gate .age-gate-content .logo {
  width: 100%;
  height: auto;
  max-width: 250px;
  margin: 0 auto;
}

#age-gate .age-gate-content .logo img {
  width: 100%;
}

#age-gate .age-gate-content h2 {
  color: inherit;
  margin: 40px auto;
}

#age-gate .age-gate-content a {
  font-weight: bold;
}

#age-gate .age-gate-content p {
  margin: 20px 0px;
  color: inherit;
}

#age-gate .age-gate-content form {
  margin-bottom: 20px;
  line-height: 1;
  text-align: center !important;
}

#age-gate .age-gate-content form .error-message {
  display: block;
  position: absolute;
  padding: 20px;
  top: 0;
  left: 0;
  width: 100%;
  background: red;
  color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#age-gate .age-gate-content .legal-text p {
  font-size: 15px;
}

#age-gate .age-gate-content .legal-text a {
  font-weight: 900;
}

@media only screen and (min-width: 768px) {
  #age-gate .age-gate-content {
    padding: 50px;
  }
}

header {
  padding: 15px;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

header .wrapper {
  text-align: left;
  height: 40px !important;
}

header .wrapper .logo-container {
  width: auto;
  height: 40px;
  line-height: 40px !important;
  display: inline-block;
}

header .wrapper .logo-container .logo {
  width: auto;
  height: 40px;
}

header .wrapper .nav-menu {
  width: 24px;
  height: 20px;
  display: block;
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

header .wrapper .nav-menu span {
  width: 100%;
  height: 3px;
  background: #8a8c8f;
  display: block;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  position: relative;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  top: 0;
}

header .wrapper .nav-menu.close span {
  background: white;
}

header .wrapper .nav-menu.close span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 10px;
}

header .wrapper .nav-menu.close span:nth-of-type(2) {
  opacity: 0;
}

header .wrapper .nav-menu.close span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: -7px;
}

header .wrapper #navigation-container {
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transform: translate(100%, -100%);
          transform: translate(100%, -100%);
  opacity: 0;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  height: 100vh;
  padding: 60px 0px;
  width: 100%;
  padding-top: 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 100;
  border-radius: 100%;
  overflow: hidden;
}

header .wrapper #navigation-container.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  border-radius: 0;
  opacity: 1;
}

header .wrapper #navigation-container.open a {
  opacity: 1;
}

header .wrapper #navigation-container .shop-dropdown {
  background: #8a8c8f;
  color: white;
  font-size: 16px;
  font-family: "Averta Bold";
  border-radius: 6px;
  padding: 5px 30px;
  margin-left: 20px;
}

header .wrapper #navigation-container .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

header .wrapper #navigation-container .background-video:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(7, 28, 43, 0.24);
}

header .wrapper #navigation-container .background-video video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

header .wrapper #navigation-container a {
  display: block;
  color: white;
  font-family: "Averta Bold";
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  cursor: pointer;
  padding: 20px;
  -webkit-transition-delay: 200ms;
          transition-delay: 200ms;
  overflow: auto;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 600ms ease-in;
  transition: all 600ms ease-in;
}

header .wrapper #navigation-container a:first-of-type {
  padding: 0px 20px;
  border: 0;
}

header .wrapper #navigation-container a:last-of-type {
  margin-right: 0;
}

header .wrapper #navigation-container a img {
  width: 50%;
  margin-bottom: 30px;
  display: inline-block;
}

header .wrapper #navigation-container .nav-close {
  width: 20px;
  height: 20px;
  background: black;
  display: block;
  position: absolute;
  left: 20px;
  top: 20px;
}

@media only screen and (min-width: 768px) {
  header {
    overflow: auto;
    padding: 20px;
  }
  header .wrapper {
    overflow: visible;
    height: auto !important;
  }
  header .wrapper .nav-menu {
    display: none;
  }
  header .wrapper .logo-container {
    display: block;
    height: 50px;
    float: left;
  }
  header .wrapper .logo-container .logo {
    height: 50px;
    width: auto;
  }
  header .wrapper #navigation-container {
    opacity: 1;
    border-radius: 0;
    overflow: visible;
    -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important;
    visibility: visible !important;
    transition: none !important;
    -webkit-box-shadow: none;
            box-shadow: none;
    position: static;
    background: none;
    width: auto;
    height: auto;
    padding: 0;
    transition: none;
    -webkit-transition: none;
    min-width: auto;
    float: right;
  }
  header .wrapper #navigation-container .background-video {
    display: none;
  }
  header .wrapper #navigation-container a {
    margin-bottom: 0;
    display: inline-block;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 900;
    -webkit-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
    border: none !important;
    padding: 0;
    line-height: 50px;
    opacity: 1;
    color: #8a8c8f;
    overflow: visible !important;
    position: relative;
  }
  header .wrapper #navigation-container a:before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    opacity: 0;
    height: 2px;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    background: #58595b;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  header .wrapper #navigation-container a.active:before {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

.extra-margin {
  height: 70px;
}

@media only screen and (min-width: 768px) {
  .extra-margin {
    height: 90px;
  }
}

.hero-carousel {
  z-index: 2;
  max-width: 400px;
  margin: 20px auto;
}

.hero-carousel .solid-background {
  padding-bottom: 20px;
  height: 100%;
}

.hero-carousel .solid-background .rich-text p,
.hero-carousel .solid-background .rich-text h2,
.hero-carousel .solid-background .rich-text h3,
.hero-carousel .solid-background .rich-text h4,
.hero-carousel .solid-background .rich-text h5,
.hero-carousel .solid-background .rich-text h6 {
  color: white !important;
}

.hero-carousel .slide {
  background: none;
}

.hero-carousel .slide img {
  max-width: 50%;
  height: 30vh;
  margin: 20px auto;
  -o-object-position: center center;
     object-position: center center;
  -o-object-fit: contain;
     object-fit: contain;
}

.hero-carousel.hide-thumbs .thumbs-wrapper {
  display: none;
}

.hero-carousel.hide-indicators .control-dots {
  display: none;
}

.hero-carousel .thumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.hero-carousel .thumbs .thumb {
  border: 1px solid transparent;
}

.hero-carousel .thumbs .thumb:hover {
  border: 1px solid #58595b;
}

.hero-carousel .thumbs .thumb.selected {
  border: 1px solid #8a8c8f;
}

.hero-carousel .control-arrow {
  color: black !important;
  background: none !important;
}

.hero-carousel .control-arrow:active {
  background: none !important;
  -webkit-tap-highlight-color: transparent !important;
  /* for removing the highlight */
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

.hero-carousel .control-arrow:hover {
  background: none;
}

.hero-carousel .control-arrow.control-prev {
  left: 20px;
}

.hero-carousel .control-arrow.control-prev:before {
  border-right: 8px solid black;
}

.hero-carousel .control-arrow.control-next {
  right: 20px;
}

.hero-carousel .control-arrow.control-next:before {
  border-left: 8px solid black;
}

.hero-carousel .carousel-status {
  display: none;
}

.hero-carousel .control-dots {
  position: static;
  text-align: center !important;
}

.hero-carousel .control-dots .dot {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #8a8c8f;
  border-radius: 0;
  width: 20px;
  height: 2px;
}

.hero-section {
  position: relative;
  overflow: hidden !important;
}

.hero-section .background-video,
.hero-section .background-image,
.hero-section .hero-carousel {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
}

.hero-section .text-content {
  -webkit-transition: all 800ms ease-in-out;
  transition: all 800ms ease-in-out;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
  opacity: 0;
}

.hero-section .background-image {
  width: 100%;
  z-index: 2;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.hero-section .background-image img {
  width: 100%;
  height: auto;
  display: block;
}

.hero-section .background-video {
  width: 100%;
  height: 50vh;
  z-index: 2;
}

.hero-section .background-video video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

.hero-section .background-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero-section .wrapper .text-content {
  z-index: 3;
  position: relative;
  padding: 50px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.hero-section .wrapper .text-content p img {
  max-width: 80%;
  margin: 0 auto;
}

.hero-section .wrapper .text-content .buttons {
  margin-top: 30px;
}

.hero-section .wrapper .text-content .buttons .button {
  margin: 10px;
}

@media only screen and (max-width: 599px) {
  .hero-section .wrapper .text-content {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    text-align: center !important;
  }
  .hero-section .wrapper .text-content .right-align {
    text-align: center !important;
  }
}

@media only screen and (min-width: 600px) {
  .hero-section .product-slides-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    margin: 50px;
  }
  .hero-section .product-slides-container img {
    width: 20%;
    -webkit-transition: all 600ms ease-in-out;
    transition: all 600ms ease-in-out;
    -webkit-transform-origin: center top !important;
            transform-origin: center top !important;
  }
  .hero-section .product-slides-container img:nth-of-type(1) {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
    -webkit-transition: all 800ms ease-in-out;
    transition: all 800ms ease-in-out;
  }
  .hero-section .product-slides-container img:nth-of-type(2) {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    z-index: 2;
  }
  .hero-section .product-slides-container img:nth-of-type(3) {
    z-index: 3;
  }
  .hero-section .product-slides-container img:nth-of-type(4) {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    z-index: 2;
  }
  .hero-section .product-slides-container img:nth-of-type(5) {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
    -webkit-transition: all 800ms ease-in-out;
    transition: all 800ms ease-in-out;
  }
  .hero-section.left-aligned .wrapper {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .hero-section.right-aligned .wrapper {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .hero-section .overlay {
    display: none;
  }
  .hero-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .hero-section .wrapper .text-content {
    width: auto;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-height: auto;
  }
  .hero-section .wrapper .text-content .buttons {
    margin-left: 0;
    margin-top: 20px;
  }
  .hero-section.vertical .wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .hero-section.vertical .wrapper .text-content {
    padding: 50px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .hero-section .background-image {
    margin: 50px;
    margin-bottom: 0;
    width: calc(100% - 100px);
  }
  .hero-section .background-video {
    margin: 50px;
    margin-bottom: 0;
    width: calc(100% - 100px);
  }
}

@media only screen and (min-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
  .section-half {
    padding: 100px 50px;
  }
  .section-half .wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .section-half .wrapper .text-content,
  .section-half .wrapper .img-content {
    width: 50%;
  }
  .section-half .wrapper .text-content {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-bottom: 0;
    margin-left: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-right: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#landing-page {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

#landing-page #logo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 40%;
  height: auto;
}

#landing-page #logo img {
  width: 100%;
  height: auto;
  display: block;
}

@media only screen and (min-width: 768px) {
  #landing-page #logo {
    width: 20%;
  }
}

.products {
  background: black;
  padding: 50px 25px;
  text-align: center;
}

.products .section-description {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.products .button {
  margin-top: 20px;
}

.products .product-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.products .product-icons .product-icon {
  text-align: center;
  width: 30%;
}

.products .product-icons .product-icon img {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.products .product-icons .product-icon h3 {
  color: white;
  text-transform: uppercase;
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  .products {
    padding: 100px 50px;
  }
  .products .product-icons .product-icon {
    width: auto;
  }
  .products .product-icons .product-icon img {
    width: 150px;
  }
}

.logo-text {
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 8px;
  white-space: nowrap;
}

footer {
  background: #110f10;
  background-size: cover;
  background-position: center center;
  color: white;
}

footer .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

footer .wrapper .footer-section {
  margin-bottom: 30px;
  width: 50%;
}

footer .wrapper .footer-section h2 {
  font-size: 20px;
}

footer .wrapper .footer-section p {
  font-size: 14px;
  margin-bottom: 10px;
}

footer .wrapper .footer-section p:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section p.copyright {
  margin-bottom: 20px;
  font-weight: 900;
}

footer .wrapper .footer-section .social {
  margin-top: 20px;
}

footer .wrapper .footer-section .social a {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: auto;
  margin-bottom: 0;
}

footer .wrapper .footer-section .social a img {
  width: 100%;
  height: auto;
}

footer .wrapper .footer-section a {
  display: block;
  color: white;
  margin-bottom: 10px;
}

footer .wrapper .footer-section a:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section:first-of-type, footer .wrapper .footer-section:last-of-type {
  width: 100%;
}

footer .wrapper .footer-section:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-width: 900px) {
  footer .wrapper {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  footer .wrapper .footer-section {
    margin-right: 20px;
    width: auto;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  footer .wrapper .footer-section .social {
    margin: 0;
  }
  footer .wrapper .footer-section .copyright {
    margin-top: 0;
  }
  footer .wrapper .footer-section:last-of-type {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}

.instagram-section {
  padding: 56px 24px;
}

.instagram-section .photos {
  margin-top: 25px;
}

.instagram-section .photos img {
  display: none;
  width: 100%;
}

.instagram-section .photos img:first-of-type {
  display: block;
}

@media only screen and (min-width: 600px) {
  .instagram-section {
    padding: 100px 50px;
  }
  .instagram-section h2 {
    float: left;
  }
  .instagram-section .button {
    float: right;
    margin-bottom: 25px;
  }
  .instagram-section .photos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    clear: both;
  }
  .instagram-section .photos img {
    display: block;
    width: calc(100% / 3 - 20px);
    margin-right: 30px;
  }
  .instagram-section .photos img:last-of-type {
    margin-right: 0;
  }
}

.products-page-section {
  min-height: 400px;
}

.products-page-section .wrapper .carousel {
  padding: 25px;
}

.products-page-section .wrapper .carousel .options span {
  display: inline-block;
  padding: 10px;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 16px;
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid transparent;
  cursor: pointer;
}

.products-page-section .wrapper .carousel .options span:last-of-type {
  margin-right: 0;
}

.products-page-section .wrapper .carousel .options span.active {
  background: black;
  color: white;
}

.products-page-section .wrapper .carousel .form-info-container .selected-form {
  width: 60%;
  height: auto;
  margin: 25px auto;
}

.products-page-section .wrapper .carousel .form-info-container .text-content p:last-of-type {
  margin-top: 15px;
}

.products-page-section .wrapper .product-carousel {
  position: relative;
}

.products-page-section .wrapper .product-carousel .images {
  width: 100%;
  height: 50vh;
}

.products-page-section .wrapper .product-carousel .images .product-image {
  width: 100%;
  height: 100%;
  display: none;
  -o-object-fit: cover !important;
     object-fit: cover !important;
  -o-object-position: center center !important;
     object-position: center center !important;
}

.products-page-section .wrapper .product-carousel .images .product-image.active {
  display: block;
}

.products-page-section .wrapper .product-carousel .controls {
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 2;
}

.products-page-section .wrapper .product-carousel .controls div {
  background: white;
  border-radius: 100%;
  float: left;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.products-page-section .wrapper .product-carousel .controls .forward:before {
  content: ">";
}

.products-page-section .wrapper .product-carousel .controls .back {
  margin-right: 20px;
}

.products-page-section .wrapper .product-carousel .controls .back:before {
  content: "<";
}

@media only screen and (min-width: 600px) {
  .products-page-section .wrapper .carousel {
    padding: 25px 50px;
  }
  .products-page-section .wrapper .carousel .options {
    text-align: center;
  }
  .products-page-section .wrapper .carousel .form-info-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .products-page-section .wrapper .carousel .form-info-container .selected-form {
    margin-right: 25px;
  }
  .products-page-section .wrapper .carousel .form-info-container .text-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 25px 0px;
  }
}

@media only screen and (min-width: 900px) {
  .products-page-section {
    padding: 50px;
  }
  .products-page-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .products-page-section .wrapper .product-carousel {
    min-width: 60%;
    margin: 0;
  }
  .products-page-section .wrapper .product-carousel .images {
    height: 100%;
  }
  .products-page-section .wrapper .carousel {
    padding: 0;
    padding-right: 25px;
    min-width: 25%;
  }
  .products-page-section .wrapper .carousel .options {
    text-align: left;
  }
  .products-page-section .wrapper .carousel .form-info-container {
    display: block;
  }
  .products-page-section .wrapper .carousel .form-info-container .selected-form {
    margin: 25px auto;
    width: 100%;
  }
  .products-page-section .wrapper .carousel .form-info-container .text-content {
    padding: 0;
  }
}

.simple-section {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .simple-section {
    padding: 100px 50px;
  }
  .simple-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .simple-section .wrapper h2 {
    margin-right: 10%;
  }
}

.simple-page {
  padding: 25px;
}

.simple-page p,
.simple-page li,
.simple-page a {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  color: black;
  letter-spacing: normal;
}

.simple-page p:last-of-type,
.simple-page li:last-of-type,
.simple-page a:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-width: 600px) {
  .simple-page {
    padding: 50px;
  }
}

.map-wrapper {
  overflow: auto;
  background-size: cover;
}

.map-wrapper .interactive-map {
  width: 100%;
  display: block;
  position: relative !important;
  display: none;
}

.map-wrapper .locations-list-wrapper {
  display: block;
  height: calc(100vh - 73px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.map-wrapper .locations-list-wrapper .locations-list-title {
  margin: 0;
  margin-top: 10px;
  padding: 0px 20px;
  color: #8a8c8f;
}

.map-wrapper .locations-list-wrapper .pagination-nav {
  padding: 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #8a8c8f;
  color: white;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span {
  cursor: pointer;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span:nth-of-type(1) {
  margin-right: 10px;
}

.map-wrapper .locations-list-wrapper .info-message {
  background: #58595b;
  color: white;
  padding: 8px 20px;
}

.map-wrapper .locations-list-wrapper .locations-search-form {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.map-wrapper .locations-list-wrapper .locations-list {
  background: white;
  overflow: scroll;
  height: 100%;
  position: relative;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location {
  padding: 20px;
  overflow: auto;
  border-bottom: 1px solid #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location:last-of-type {
  border-bottom: 0;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location.active {
  background: rgba(0, 0, 0, 0.2);
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #000000;
  width: 100%;
  color: #8a8c8f;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-name {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #58595b;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .distance {
  font-size: 14px;
  line-height: 1.2;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .usage-type {
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 0;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions {
  min-width: 110px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button {
  color: #58595b;
  font-size: 10px;
  display: block;
  text-decoration: none;
  font-weight: 900;
  margin-bottom: 10px;
  white-space: nowrap;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  fill: #58595b;
}

@media only screen and (min-width: 768px) {
  .map-wrapper {
    overflow: auto;
  }
  .map-wrapper .interactive-map {
    height: calc(100vh - 70px) !important;
    width: 60% !important;
    float: right;
    display: block;
  }
  .map-wrapper .stores-wrapper {
    height: calc(100vh - 70px) !important;
    width: 40%;
    float: left;
  }
}

.gm-style {
  font: inherit !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 0 !important;
}

.info-window-content .store-name {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #58595b;
}

.info-window-content .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.info-window-content .usage-type {
  font-size: 16px;
  margin-bottom: 10px;
}

.info-window-content a {
  text-decoration: none;
}

#alert-dialog-description p a {
  color: black;
  text-decoration: underline;
}

.no-flick {
  min-height: 100vh;
}

.animation {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}

.animation.yas {
  opacity: 1;
}

.animation.yas .hero-section .background-image,
.animation.yas .hero-section .background-video,
.animation.yas .hero-section .hero-carousel {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.animation.yas .hero-section .text-content {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.animation.yas .hero-section .product-slides-container:hover img {
  -webkit-transform: scale(0.9) !important;
          transform: scale(0.9) !important;
}

.animation.yas .hero-section .product-slides-container img {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.animation.yas .hero-section .product-slides-container img:nth-of-type(1) {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.animation.yas .hero-section .product-slides-container img:nth-of-type(3) {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.animation.yas .hero-section .product-slides-container img:nth-of-type(5) {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.animation.yas .hero-section .product-slides-container img:hover {
  -webkit-transform: scale(1.2) !important;
          transform: scale(1.2) !important;
}

.map-info-window .button {
  margin-top: 10px;
}

.featured-section .wrapper .featured-item {
  margin: 0 auto;
  margin-bottom: 16px;
}

.featured-section .wrapper .featured-item:last-of-type {
  margin-bottom: 0;
}

.featured-section .wrapper .featured-item .image {
  border-radius: 10px;
  border: 3px solid white;
  overflow: hidden;
}

.featured-section .wrapper .featured-item .image img {
  width: 100%;
  height: auto;
  display: block;
}

.featured-section .wrapper .featured-item .content {
  padding: 20px;
}

@media only screen and (min-width: 600px) {
  .featured-section .wrapper {
    max-width: 900px;
  }
  .featured-section .wrapper .featured-item {
    margin-bottom: 0 !important;
    max-width: calc(50% - 25px);
  }
  .featured-section .wrapper .featured-item .image {
    margin: 0 auto;
  }
  .featured-section .wrapper .featured-item:first-of-type {
    margin-right: 25px;
  }
  .featured-section .wrapper .featured-item:last-of-type {
    margin-left: 25px;
  }
  .featured-section .wrapper .featured-item .content {
    padding: 20px 0px;
  }
}

@media only screen and (min-width: 1200px) {
  .featured-section .wrapper .featured-item {
    max-width: calc(50% - 24px);
  }
  .featured-section .wrapper .featured-item:first-of-type {
    margin-right: 24px;
  }
  .featured-section .wrapper .featured-item:last-of-type {
    margin-left: 24px;
  }
}

.text-section {
  background-size: cover !important;
  background-position: center center !important;
}

.text-section .wrapper *:last-of-type {
  margin-bottom: 0;
}

.text-section .wrapper p {
  margin: 20px 0px;
}

.text-section .wrapper .image-wrapper {
  margin-top: 40px;
}

.text-section .wrapper .image-wrapper img {
  max-width: 80%;
  margin: 0 auto;
}

@media only screen and (min-width: 600px) {
  .text-section {
    padding: 114px 48px;
  }
}

@media only screen and (min-width: 768px) {
  .flex-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .flex-wrapper.lead-image .image-wrapper {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
}

.product-section .wrapper {
  overflow: visible;
}

.product-section .wrapper .button-wrapper {
  margin: 24px 0px;
}

.product-section .wrapper .products-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.product-section .wrapper .products-wrapper .product {
  width: 100%;
  max-width: 150px;
}

.product-section .wrapper .products-wrapper .product.extra-padding {
  margin: 0;
  padding: 40px;
  max-width: 300px;
}

.product-section .wrapper .products-wrapper .product.solid-background .rich-text p,
.product-section .wrapper .products-wrapper .product.solid-background .rich-text h2,
.product-section .wrapper .products-wrapper .product.solid-background .rich-text h3,
.product-section .wrapper .products-wrapper .product.solid-background .rich-text h4,
.product-section .wrapper .products-wrapper .product.solid-background .rich-text h5,
.product-section .wrapper .products-wrapper .product.solid-background .rich-text h6 {
  color: white !important;
}

.product-section .wrapper .products-wrapper .product a {
  text-align: center;
}

.product-section .wrapper .products-wrapper .product a:hover img {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.product-section .wrapper .products-wrapper .product img {
  width: 100%;
  display: block;
  max-width: 250px;
  height: 250px;
  -o-object-position: center center;
     object-position: center center;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0 auto;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  margin-bottom: 20px;
}

.product-section .wrapper .products-wrapper .product .content {
  margin-top: 20px;
}

.product-section .wrapper .products-wrapper .product .rich-text {
  width: 100%;
}

.product-section .wrapper .products-wrapper.animate .product {
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
  -webkit-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
  opacity: 0;
}

.product-section .wrapper .products-wrapper.animate .product:nth-of-type(1) {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

.product-section .wrapper .products-wrapper.animate .product:nth-of-type(2) {
  -webkit-transition-delay: 100ms !important;
          transition-delay: 100ms !important;
}

.product-section .wrapper .products-wrapper.animate .product:nth-of-type(3) {
  -webkit-transition-delay: 200ms !important;
          transition-delay: 200ms !important;
}

.product-section .wrapper .products-wrapper.animate .product:nth-of-type(4) {
  -webkit-transition-delay: 200ms !important;
          transition-delay: 200ms !important;
}

.product-section .wrapper .products-wrapper.animate .product:nth-of-type(5) {
  -webkit-transition-delay: 400ms !important;
          transition-delay: 400ms !important;
}

.product-section .wrapper .products-wrapper.animate.animation-active .product {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .product-section .wrapper .content-wrapper {
    margin-right: 30px;
    margin-bottom: 50px;
  }
  .product-section .wrapper .flex-wrapper {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .product-section .wrapper .products-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .product-section .wrapper .products-wrapper .product {
    margin: 0px 20px;
  }
}

.card-swipe-section.standard-padding {
  padding-bottom: 35px;
}

.card-swipe-section .button-wrapper {
  margin: 24px 0px;
}

.card-swipe-section .products-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  padding-bottom: 15px;
}

.card-swipe-section .products-wrapper .card {
  min-width: 230px;
  min-height: 230px;
  margin-right: 16px;
  position: relative;
  border-radius: 10px;
  border: 3px solid white;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
}

.card-swipe-section .products-wrapper .card:hover .content {
  opacity: 1;
}

.card-swipe-section .products-wrapper .card .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.card-swipe-section .products-wrapper .card .image img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.card-swipe-section .products-wrapper .card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.card-swipe-section .products-wrapper .card .content h2 {
  margin-bottom: 10px;
}

.card-swipe-section .products-wrapper .card .content h3 {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .card-swipe-section .wrapper .flex-wrapper .flex-child:nth-of-type(1) {
    margin-right: 48px;
    max-width: 50%;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child:nth-of-type(2) {
    width: 100%;
    width: 50%;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    -webkit-column-gap: 24px;
            column-gap: 24px;
    row-gap: 24px;
    padding: 0;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper .card {
    margin: 0;
    min-width: auto;
    min-height: auto;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper .card .image {
    position: static;
  }
}

.text-image-section .wrapper {
  max-width: 1300px;
}

.text-image-section .wrapper .content-wrapper {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
}

.text-image-section .wrapper .button-wrapper {
  margin-top: 24px;
}

.text-image-section .wrapper .button-wrapper .button {
  min-width: 210px;
  text-align: center;
}

.text-image-section .wrapper .image-wrapper {
  position: relative;
}

.text-image-section .wrapper .image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

.text-image-section .wrapper.full-width .flex-wrapper .content-wrapper .content-wrapper-inner .section1 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) {
  .text-image-section .wrapper.full-width .flex-wrapper .content-wrapper .content-wrapper-inner .section1 {
    margin-bottom: 70px;
  }
}

.text-image-section .wrapper.full-width .flex-wrapper .image-wrapper img {
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 30vh;
}

.text-image-section.animate .wrapper .content-wrapper {
  -webkit-transform: translateX(100px) scale(0.9);
          transform: translateX(100px) scale(0.9);
  opacity: 0;
  -webkit-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
}

.text-image-section.animate .wrapper .image-wrapper {
  -webkit-transform: translateX(-100px) scale(0.9);
          transform: translateX(-100px) scale(0.9);
  opacity: 0;
  -webkit-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
}

.text-image-section.animate .wrapper .lead-image .content-wrapper {
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
}

.text-image-section.animate .wrapper .lead-image .image-wrapper {
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
}

.text-image-section.animate.animation-active .wrapper .content-wrapper,
.text-image-section.animate.animation-active .wrapper .image-wrapper {
  -webkit-transform: translateX(0) scale(1);
          transform: translateX(0) scale(1);
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .text-image-section .wrapper .image-wrapper {
    min-width: 50%;
    max-width: 50%;
    margin-left: 0;
    margin-top: 0;
    max-width: none;
  }
  .text-image-section .wrapper .image-wrapper img {
    margin: 40px 0px;
  }
  .text-image-section .wrapper.full-width {
    max-width: none !important;
  }
  .text-image-section .wrapper.full-width .flex-wrapper .image-wrapper {
    margin-left: 0;
    margin-top: 0;
    max-width: none;
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
  .text-image-section .wrapper.full-width .flex-wrapper .image-wrapper img {
    margin: 0 !important;
    max-height: 50vh;
  }
  .text-image-section .wrapper.full-width .content-wrapper .content-wrapper-inner {
    max-width: 550px;
    width: 100%;
    float: right;
  }
  .text-image-section .wrapper .content-wrapper {
    text-align: inherit;
  }
}

@media only screen and (min-width: 768px) {
  .button-wrapper .button {
    width: auto !important;
  }
}

.forms-section {
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  overflow: hidden;
}

.forms-section .wrapper .flex-wrapper {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.forms-section .wrapper .flex-wrapper .content-wrapper {
  margin-bottom: 15px;
  z-index: 1;
}

.forms-section .wrapper .flex-wrapper .content-wrapper .text-content {
  margin-bottom: 20px;
}

.forms-section .wrapper .flex-wrapper .content-wrapper .pills {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  padding-bottom: 15px;
}

.forms-section .wrapper .flex-wrapper .content-wrapper .pills .button {
  margin-right: 8px;
  cursor: pointer;
}

.forms-section .wrapper .flex-wrapper .content-wrapper .pills .button.active {
  background: #fee602 !important;
  color: black !important;
  border: 2px solid #fee602 !important;
}

.forms-section .wrapper .flex-wrapper .image-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.forms-section .wrapper .flex-wrapper .image-wrapper .content {
  margin-top: 30px;
}

.forms-section .wrapper .flex-wrapper .image-wrapper .form-images {
  position: relative;
  height: 50vh;
  z-index: 0;
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image.visible {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image img {
  height: 100%;
  width: 100%;
  max-width: 400px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center center;
     object-position: center center;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.forms-section .wrapper .form-content {
  display: none;
}

.forms-section .wrapper .form-content.visible {
  display: block;
}

@media only screen and (min-width: 768px) {
  .forms-section .wrapper .flex-wrapper {
    min-height: 70vh;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper .text-content {
    padding-right: 20px;
    width: auto;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper .text-content h2 {
    margin: 0;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper .pills {
    display: block;
    overflow: unset;
    width: auto;
    padding-bottom: 0;
    text-align: right;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper .pills .button {
    margin-bottom: 8px;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper {
    min-width: 50%;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .content {
    width: 50%;
    margin-top: 0;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images {
    width: 50%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image {
    padding: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image.visible {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image img {
    max-width: none;
    -o-object-position: center right;
       object-position: center right;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images.lead-image {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images.lead-image img {
    -o-object-position: center left;
       object-position: center left;
  }
}

.standard-padding {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .standard-padding {
    padding: 50px;
  }
}

.faq-section .question {
  cursor: pointer;
}

.faq-section table h2,
.faq-section table h3,
.faq-section table h4,
.faq-section table h5,
.faq-section table h6 {
  margin: 0 !important;
}

.faq-section table div {
  margin: 0;
}

.faq-section table .answer {
  padding: 20px;
}

.full-screen-video-section {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.full-screen-video-section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

.full-screen-video-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.full-screen-video-section .logo-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.full-screen-video-section .logo-content .video-logo img {
  width: 100%;
  max-width: 450px;
  margin-bottom: 50px;
  padding: 0px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.full-screen-video-section .logo-content .content {
  color: white;
}

.full-screen-video-section .logo-content .content a {
  text-decoration: underline;
}

.full-screen-video-section .disclaimers {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: white;
  text-align: center;
}

.full-screen-video-section .disclaimers a {
  text-decoration: underline !important;
}

.box-padding {
  padding: 30px;
}

.animate {
  -webkit-transform: translateY(100px) scale(0.9);
          transform: translateY(100px) scale(0.9);
  -webkit-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
  opacity: 0;
}

.animate.animation-active {
  -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
  opacity: 1;
}

.custom-animation {
  -webkit-transform: none;
          transform: none;
  -webkit-transition: none;
  transition: none;
  opacity: 1;
}

.photo-section {
  margin-bottom: 12px;
}

.photo-section .photo-section-item {
  height: 100%;
  margin-bottom: 12px;
}

.photo-section .photo-section-item .image-wrapper {
  z-index: 2;
}

.photo-section .photo-section-item .image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

.photo-section .photo-section-item .content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.photo-section .photo-section-item .content .product-image {
  height: 100%;
  width: 50px;
  margin-right: 50px;
  -o-object-position: center center;
     object-position: center center;
  -o-object-fit: contain;
     object-fit: contain;
}

.photo-section .photo-section-item.animate .image-wrapper,
.photo-section .photo-section-item.animate .content {
  -webkit-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.photo-section .photo-section-item.animate .image-wrapper {
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
}

.photo-section .photo-section-item.animate .content {
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
}

.photo-section .photo-section-item.animate.animation-active .image-wrapper,
.photo-section .photo-section-item.animate.animation-active .content {
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
}

.photo-section .photo-section-item.animate:nth-of-type(even) .image-wrapper {
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
}

.photo-section .photo-section-item.animate:nth-of-type(even) .content {
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
}

@media only screen and (min-width: 768px) {
  .photo-section {
    padding: 50px;
  }
  .photo-section .photo-section-item {
    margin-bottom: 40px;
  }
  .photo-section .photo-section-item .image-wrapper {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .photo-section .photo-section-item .content {
    margin: 40px 0px;
    text-align: inherit;
    padding: 50px;
    height: calc(100% - 80px);
  }
  .photo-section .photo-section-item.lead-image .image-wrapper {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
}

.moods-section {
  margin-bottom: 40px;
}

.moods-section .buttons {
  margin-top: 20px;
}

.moods-section .moods-wrapper .mood-item {
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  background-position: center center !important;
  background-size: cover !important;
}

.moods-section .moods-wrapper .mood-item:last-of-type {
  margin-bottom: 0;
}

.moods-section .moods-wrapper .mood-item.coming-soon:before {
  content: "coming soon";
  position: absolute;
  top: 13px;
  left: -50px;
  color: #8a8c8f;
  background: white;
  padding: 5px 60px;
  font-size: 10px;
  -webkit-transform: rotate(-25deg);
          transform: rotate(-25deg);
}

.moods-section .moods-wrapper .mood-item .buttons .button {
  margin-left: 0;
  margin-right: 0;
  white-space: pre-wrap !important;
  text-align: center;
  font-size: 13px !important;
  padding: 10px 20px !important;
  letter-spacing: 1px;
  min-width: 190px;
}

.moods-section .moods-wrapper .mood-item .toggle {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  z-index: 10;
  text-align: center;
  cursor: pointer;
}

.moods-section .moods-wrapper .mood-item .toggle .toggle-icon {
  font-size: 20px;
  line-height: 40px;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}

.moods-section .moods-wrapper .mood-item .toggle .toggle-icon.open {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.moods-section .moods-wrapper .mood-item .toggle .toggle-text {
  line-height: 10px;
  font-size: 13px;
}

.moods-section .moods-wrapper .mood-item .lifestyle-image {
  display: none;
  background-size: cover !important;
  background-position: center center !important;
}

.moods-section .moods-wrapper .mood-item .content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 40px;
  -webkit-user-select: none;
  position: relative;
  overflow: hidden;
}

.moods-section .moods-wrapper .mood-item .content .text-content {
  margin: 40px 0px;
  width: 70%;
}

.moods-section .moods-wrapper .mood-item .content .text-content h2,
.moods-section .moods-wrapper .mood-item .content .text-content h3,
.moods-section .moods-wrapper .mood-item .content .text-content h4,
.moods-section .moods-wrapper .mood-item .content .text-content h5 {
  font-family: "Domain Display";
  text-transform: lowercase;
  font-size: 40px;
  letter-spacing: 4px;
}

.moods-section .moods-wrapper .mood-item .content .text-content p {
  font-size: 15px;
}

.moods-section .moods-wrapper .mood-item .content .text-content h6 {
  color: white;
}

.moods-section .moods-wrapper .mood-item .content .text-content .text-content-2 {
  padding: 20px 0px;
}

.moods-section .moods-wrapper .mood-item .content .product-image {
  width: 30%;
  height: 100%;
  margin-left: 40px;
  position: absolute;
  top: 40px;
  right: 20px;
}

.moods-section .moods-wrapper .mood-item .content .product-image img {
  width: 100%;
  height: 50vh;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center center;
     object-position: center center;
}

.moods-section .moods-wrapper .mood-item:nth-of-type(even) .content .product-image {
  left: 20px;
  right: auto;
  margin-left: 0;
  margin-right: 40px;
}

.moods-section .moods-wrapper .mood-item:nth-of-type(even) .content .text-content {
  float: right;
}

.moods-section .moods-wrapper .mood-item.animate .product-image {
  -webkit-transform: translateY(200px);
          transform: translateY(200px);
  -webkit-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
  -webkit-transition-delay: 200ms;
          transition-delay: 200ms;
  opacity: 0;
}

.moods-section .moods-wrapper .mood-item.animate .text-content {
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
  opacity: 0;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.moods-section .moods-wrapper .mood-item.animate.animation-active .product-image {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.moods-section .moods-wrapper .mood-item.animate.animation-active .text-content {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .moods-section .moods-wrapper {
    padding-bottom: 50px;
  }
  .moods-section .moods-wrapper .mood-item {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 300px;
  }
  .moods-section .moods-wrapper .mood-item .lifestyle-image {
    min-width: 45%;
    display: block;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    z-index: 2;
  }
  .moods-section .moods-wrapper .mood-item .lifestyle-image img {
    height: 100%;
  }
  .moods-section .moods-wrapper .mood-item .content {
    min-width: 55%;
    padding: 0% 10%;
  }
  .moods-section .moods-wrapper .mood-item:nth-of-type(odd) .lifestyle-image {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .moods-section .moods-wrapper .mood-item:nth-of-type(odd).coming-soon:before {
    left: auto;
    right: -50px;
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
}

.product-gallery-section .wrapper .text-content {
  text-align: center;
}

.product-gallery-section .wrapper .text-content h3 {
  color: #cf5442;
}

.product-gallery-section .wrapper .text-content .buttons {
  margin-top: 40px;
}

.product-gallery-section .wrapper .text-content.animate {
  -webkit-transform: translateX(-100px) scale(0.9);
          transform: translateX(-100px) scale(0.9);
  opacity: 0;
  -webkit-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.product-gallery-section .wrapper .text-content.animate.animation-active {
  -webkit-transform: translateX(0) scale(1);
          transform: translateX(0) scale(1);
  opacity: 1;
}

@media only screen and (min-width: 960px) {
  .product-gallery-section .wrapper .hero-carousel {
    margin-bottom: 0;
    margin: 40px;
    background: white;
  }
  .product-gallery-section .wrapper .text-content {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    text-align: inherit;
  }
}

.pdf-section .react-pdf__Document {
  pointer-events: none;
  -webkit-user-select: none;
  overflow: hidden;
}

.pdf-section .react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.pdf-section .pdf-navigation {
  text-align: center;
  z-index: 20;
  position: relative;
}

.pdf-section .pdf-navigation .page-indicator {
  margin-bottom: 10px;
}

.pdf-section .pdf-navigation .button:first-of-type {
  margin-right: 10px;
}

.pdf-section .pdf-navigation .button {
  z-index: 20;
}

.MuiMenuItem-root {
  font-family: "Averta Bold" !important;
  color: #8a8c8f !important;
}

.column-section .columns .column {
  padding: 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-position: center center;
  background-size: cover;
}

.column-section .columns .column .foreground-image img {
  width: 100%;
}

.column-section .columns .column .buttons {
  text-align: center;
}

.column-section .columns .column h2 {
  font-family: "Domain Display";
  text-transform: lowercase;
  font-size: 40px;
  letter-spacing: 4px;
  margin-bottom: 0;
}

.sl-iframe-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 3915px;
}

@media screen and (min-width: 600px) {
  .sl-iframe-container {
    width: 100%;
    height: 4000px;
  }
}

@media screen and (min-width: 768px) {
  .sl-iframe-container {
    width: 100%;
    height: 2760px;
  }
}

.sl-iframe-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  left: 0;
  top: 0;
}

.microcast-slice {
  background-position: center center !important;
  background-size: cover !important;
}

.microcast-slice .wrapper .content {
  padding: 20px;
  padding-bottom: 300px;
  text-align: center;
  color: white;
}

.microcast-slice .wrapper .content .special-font {
  font-style: italic;
  font-size: 32px;
}

.microcast-slice .wrapper .button-container {
  margin-top: 20px;
  text-align: center;
}

.microcast-slice .wrapper .button-container .special-button {
  display: inline-block;
  margin-right: 10px;
}

.microcast-slice .wrapper .button-container .special-button:last-of-type {
  margin-right: 0px;
}

.microcast-slice .wrapper .button-container .special-button img {
  width: 100px;
}

@media screen and (min-width: 600px) {
  .microcast-slice .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -ms-flex-line-pack: center;
        align-content: center;
  }
  .microcast-slice .wrapper .content {
    width: 50%;
    text-align: left;
    padding: 0px;
    padding: 150px 0px;
  }
  .microcast-slice .wrapper .button-container {
    text-align: left;
  }
  .microcast-slice .wrapper .button-container .special-button img {
    width: 150px;
  }
}

#contact-page {
  margin-top: 70px;
}

#contact-page .wrapper {
  max-width: 700px;
  padding: 20px;
  margin: 2rem auto;
}

@media screen and (min-width: 768px) {
  #contact-page {
    margin-top: 100px;
  }
}

#contact-header {
  background: #58595b;
}

#contact-header h1 {
  text-align: center;
  padding: 80px 0px;
  color: white;
  margin: 0;
}

.form-message {
  display: block;
  background: #8a8c8f;
  color: white;
  padding: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
}

.form-message.error {
  color: black;
  border: 1px solid #ad0000;
  background: white;
  color: #ad0000;
}

.underline {
  text-decoration: underline;
  font-weight: bold;
}

.MuiFormLabel-root.Mui-error {
  background: none !important;
  color: #ad0000 !important;
  display: inline-block !important;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ad0000 !important;
}

.accessibility {
  display: inline-block;
  position: relative;
  bottom: 6px;
  margin-left: 10px;
}

.accessibility .MuiTypography-root {
  color: white;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
  white-space: nowrap;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}
