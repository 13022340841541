$gray: #979797;
$yellow: #ffcf00;
$new-yellow: #fee602;
$new-darkBlue: #0069b5;
$new-lightBlue: #00a9ea;
$blue: #009fd9;
$red: #ce0e2d;
$rose: #f48793;
$violet: #b6b8dd;
$black: #110f10;
$errorRed: #ad0000;

$wonder-darkGray: #58595b;
$wonder-gray: #8a8c8f;
$wonder-lightGray: #f4f5f5;

$wonder-lightBlue: #4c9fb5;
$wonder-green: #4ea38d;
$wonder-darkBlue: #2f688a;
$wonder-orange: #eba952;
$wonder-red: #cf5442;

@import "imports/modal";

@font-face {
  font-family: "Averta Regular";
  src: url("../fonts/Averta-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Averta Bold";
  font-weight: bold;
  src: url("../fonts/Averta-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Domain Display";
  font-weight: normal;
  src: url("../fonts/DomaineDisplay-Semibold.otf") format("opentype");
}

.mr-10 {
  margin-right: 10px;
}

.yellow {
  color: #ffcf00;
}
.blue {
  color: #009fd9;
}
.red {
  color: #ce0e2d;
}
.rose {
  color: #f48793;
}
.violet {
  color: #b6b8dd;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Averta Regular";
  letter-spacing: 2px;
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  overflow: auto;
  overflow: hidden;
}

.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40px {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 10px !important;
}
.mr-10 {
  margin-right: 20px !important;
}
.mr-15 {
  margin-right: 30px !important;
}
.mr-20 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 10px !important;
}
.ml-10 {
  margin-left: 20px !important;
}
.ml-15 {
  margin-left: 30px !important;
}
.ml-20 {
  margin-left: 40px !important;
}

.center-align {
  text-align: left !important;
}
.center-align {
  text-align: center !important;
}
.right-align {
  text-align: right !important;
}

.flex {
  display: flex;
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

input,
textarea,
select {
  box-shadow: none !important;
  border-radius: 0 !important;
}
input {
  -webkit-box-shadow: none;
  box-shadow: none;
}
img {
  display: block;
}
h1 {
  margin: 0;
  white-space: pre-wrap;
  font-family: "Averta Bold";
  font-size: 36px;
  letter-spacing: 3.6px;
}
h2 {
  margin: 0;
  font-family: "Averta Bold";
  font-size: 28px;
}
h3 {
  margin: 0;
  font-family: "Averta Bold";
  font-size: 20px;
  letter-spacing: 2px;
}
h6 {
  margin: 0;
  color: $wonder-gray;
  // font-family: "Averta Bold";
  font-size: 12px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 20px;
  // color: $wonder-darkGray;
}
p {
  margin: 0;
  font-size: 16px;
  letter-spacing: 1.6px;
  // color: $wonder-gray;
}
img {
  display: block;
}

.gray-headlines-and-copy {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $wonder-darkGray;
  }
  p,
  a:not(.button),
  ul,
  li,
  ol {
    color: $wonder-gray;
  }
}

.gray-headlines-white-button-copy {
  color: $wonder-darkGray;
  a {
    color: white;
  }
}

ul {
  text-align: left !important;
  padding: 0;
  li {
  }
}

a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
  h6 {
    // font-size: 18px;
  }
  p {
    font-size: 18px;
  }
}
.button {
  display: inline-block !important;
  -webkit-appearance: none !important;
  font-size: 16px !important;
  padding: 8px 40px !important;
  color: white;
  background: $wonder-gray;
  border: none !important;
  outline: none !important;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  white-space: nowrap !important;
  border-radius: 6px !important;
  text-transform: unset !important;
  cursor: pointer;
  font-family: "Averta Bold" !important;
  &:hover {
    color: white !important;
    background: darken($wonder-gray, 10%) !important;
    cursor: pointer;
  }
}

#age-gate {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .age-gate-content {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    border-radius: 4px;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    z-index: 3;
    max-height: 600px;
    align-self: center;
    text-align: center;
    position: relative;
    .spanish-toggle {
      position: absolute;
      top: 0;
      right: 0;
    }
    .logo {
      width: 100%;
      height: auto;
      max-width: 250px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    h2 {
      color: inherit;
      margin: 40px auto;
    }
    a {
      font-weight: bold;
    }
    p {
      margin: 20px 0px;
      color: inherit;
    }
    form {
      margin-bottom: 20px;
      line-height: 1;
      text-align: center !important;

      .error-message {
        display: block;
        position: absolute;
        padding: 20px;
        top: 0;
        left: 0;
        width: 100%;
        background: red;
        color: white;
        box-sizing: border-box;
      }
    }
    .legal-text {
      p {
        font-size: 15px;
      }
      a {
        font-weight: 900;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #age-gate {
    .age-gate-content {
      padding: 50px;
    }
  }
}

header {
  padding: 15px;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  .wrapper {
    text-align: left;
    height: 40px !important;
    .logo-container {
      width: auto;
      height: 40px;
      line-height: 40px !important;
      display: inline-block;
      .logo {
        width: auto;
        height: 40px;
      }
    }
    .nav-menu {
      width: 24px;
      height: 20px;
      display: block;
      position: absolute;
      right: 20px;
      top: 25px;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span {
        width: 100%;
        height: 3px;
        background: $wonder-gray;
        display: block;
        transform-origin: center center;
        position: relative;
        transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
        top: 0;
      }
      &.close {
        span {
          background: white;
        }
        span:nth-of-type(1) {
          transform: rotate(45deg);
          top: 10px;
        }
        span:nth-of-type(2) {
          opacity: 0;
        }
        span:nth-of-type(3) {
          transform: rotate(-45deg);
          top: -7px;
        }
      }
    }
    #navigation-container {
      &.open {
        transform: translateX(0);
        border-radius: 0;
        opacity: 1;
        a {
          opacity: 1;
        }
      }
      transition: all 300ms ease-out;
      transform: translate(100%, -100%);
      opacity: 0;
      text-align: left;
      position: fixed;
      top: 0;
      left: 0;
      background: white;
      height: 100vh;
      padding: 60px 0px;
      width: 100%;
      padding-top: 100px;
      box-sizing: border-box;
      z-index: 100;
      border-radius: 100%;
      overflow: hidden;
      .shop-dropdown {
        background: $wonder-gray;
        color: white;
        font-size: 16px;
        font-family: "Averta Bold";
        border-radius: 6px;
        padding: 5px 30px;
        margin-left: 20px;
      }
      .background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(7, 28, 43, 0.24);
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
      a {
        display: block;
        color: white;
        font-family: "Averta Bold";
        font-size: 22px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        cursor: pointer;
        padding: 20px;
        transition-delay: 200ms;
        // border-top: 1px solid #eaeaea;
        overflow: auto;
        text-align: center;
        opacity: 0;
        transition: all 600ms ease-in;
        // &:hover {
        //   color: $wonder-gray;
        // }
        // &.active {
        //   color: $wonder-lightBlue;
        // }
        &:first-of-type {
          padding: 0px 20px;
          border: 0;
        }
        &:last-of-type {
          // border-bottom: 1px solid #eaeaea;
          margin-right: 0;
        }
        img {
          width: 50%;
          margin-bottom: 30px;
          display: inline-block;
          // float: right;
          // display: none;
        }
      }
      .nav-close {
        width: 20px;
        height: 20px;
        background: black;
        display: block;
        position: absolute;
        left: 20px;
        top: 20px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  header {
    overflow: auto;
    padding: 20px;
    .wrapper {
      overflow: visible;
      height: auto !important;
      .nav-menu {
        display: none;
      }
      .logo-container {
        display: block;
        height: 50px;
        float: left;
        .logo {
          height: 50px;
          width: auto;
        }
      }
      #navigation-container {
        opacity: 1;
        border-radius: 0;
        overflow: visible;
        .background-video {
          display: none;
        }
        transform: translateX(0) !important;
        visibility: visible !important;
        transition: none !important;
        box-shadow: none;
        position: static;
        background: none;
        width: auto;
        height: auto;
        padding: 0;
        transition: none;
        -webkit-transition: none;
        min-width: auto;
        a {
          margin-bottom: 0;
          display: inline-block;
          margin-right: 20px;
          font-size: 16px;
          font-weight: 900;
          -webkit-transition: all 100ms ease-in-out;
          transition: all 100ms ease-in-out;
          border: none !important;
          padding: 0;
          line-height: 50px;
          opacity: 1;
          color: $wonder-gray;
          overflow: visible !important;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 100%;
            opacity: 0;
            height: 2px;
            transition: all 400ms ease-in-out;
            background: $wonder-darkGray;
            transform: scaleX(0);
          }
          &.active {
            &:before {
              opacity: 1;
              transform: scaleX(1);
            }
            // color: $wonder-darkBlue;
            // border-bottom: 1px solid $wonder-darkGray !important
          }
        }
        float: right;
      }
    }
  }
}
.extra-margin {
  height: 70px;
}

@media only screen and (min-width: 768px) {
  .extra-margin {
    height: 90px;
  }
}

.hero-carousel {
  z-index: 2;
  max-width: 400px;
  margin: 20px auto;
  .solid-background {
    padding-bottom: 20px;
    height: 100%;
    .rich-text {
      p,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: white !important;
      }
    }
  }

  .slide {
    background: none;
    img {
      max-width: 50%;
      height: 30vh;
      margin: 20px auto;
      object-position: center center;
      object-fit: contain;
    }
  }
  &.hide-thumbs {
    .thumbs-wrapper {
      display: none;
    }
  }
  &.hide-indicators {
    .control-dots {
      display: none;
    }
  }
  .thumbs {
    display: flex;
    justify-content: center;

    .thumb {
      border: 1px solid transparent;
      &:hover {
        border: 1px solid $wonder-darkGray;
      }
      &.selected {
        border: 1px solid $wonder-gray;
      }
    }
  }
  .control-arrow {
    color: black !important;
    background: none !important;

    &:active {
      background: none !important;
      -webkit-tap-highlight-color: transparent !important; /* for removing the highlight */
      transform: scale(0.5);
    }
    &:hover {
      background: none;
    }
    &.control-prev {
      left: 20px;
      &:before {
        border-right: 8px solid black;
      }
    }
    &.control-next {
      right: 20px;
      &:before {
        border-left: 8px solid black;
      }
    }
  }
  .carousel-status {
    display: none;
  }
  .control-dots {
    position: static;
    text-align: center !important;
    .dot {
      box-shadow: none;
      background: $wonder-gray;
      border-radius: 0;
      width: 20px;
      height: 2px;
    }
  }
}
.hero-section {
  position: relative;
  overflow: hidden !important;

  .background-video,
  .background-image,
  .hero-carousel {
    transform: scale(1.2);
    transition: transform 500ms ease-in-out;
  }
  .text-content {
    transition: all 800ms ease-in-out;
    transform: translateY(100px);
    opacity: 0;
  }
  .background-image {
    width: 100%;
    z-index: 2;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .background-video {
    width: 100%;
    height: 50vh;
    z-index: 2;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .background-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .wrapper {
    .text-content {
      z-index: 3;
      position: relative;
      padding: 50px 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        // color: $wonder-darkGray;
      }
      p {
        // color: $wonder-gray;
        img {
          max-width: 80%;
          margin: 0 auto;
        }
      }
      .buttons {
        margin-top: 30px;
        .button {
          margin: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .hero-section {
    .wrapper {
      .text-content {
        justify-content: flex-end;
        text-align: center !important;
        .right-align {
          text-align: center !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .hero-section {
    .product-slides-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 50px;
      img {
        width: 20%;
        transition: all 600ms ease-in-out;
        transform-origin: center top !important;
        // opacity: 0;
        &:nth-of-type(1) {
          transform: translateX(200%);
          transition: all 800ms ease-in-out;
        }
        &:nth-of-type(2) {
          transform: translateX(100%);
          z-index: 2;
        }
        &:nth-of-type(3) {
          z-index: 3;
        }
        &:nth-of-type(4) {
          transform: translateX(-100%);
          z-index: 2;
        }
        &:nth-of-type(5) {
          transform: translateX(-200%);
          transition: all 800ms ease-in-out;
        }
      }
    }
    &.left-aligned {
      .wrapper {
        justify-content: flex-start;
      }
    }
    &.right-aligned {
      .wrapper {
        justify-content: flex-end;
      }
    }
    .overlay {
      display: none;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      .text-content {
        // padding: 170px 50px;
        width: auto;
        width: 100%;
        box-sizing: border-box;
        min-height: auto;
        .buttons {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
    &.vertical {
      .wrapper {
        flex-direction: column;
        .text-content {
          padding: 50px;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .hero-section {
    .background-image {
      margin: 50px;
      margin-bottom: 0;
      width: calc(100% - 100px);
    }
    .background-video {
      margin: 50px;
      margin-bottom: 0;
      width: calc(100% - 100px);
    }
  }
}

@media only screen and (min-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
  .section-half {
    padding: 100px 50px;
    .wrapper {
      flex-direction: row;
      .text-content,
      .img-content {
        width: 50%;
      }
      .text-content {
        order: 2;
        margin-bottom: 0;
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 40px;
        box-sizing: border-box;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#landing-page {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 1s;

  #logo {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 40%;
    height: auto;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

@media only screen and (min-width: 768px) {
  #landing-page {
    #logo {
      width: 20%;
    }
  }
}

.products {
  background: black;
  padding: 50px 25px;
  text-align: center;
  .section-description {
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  .button {
    margin-top: 20px;
  }

  .product-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    .product-icon {
      text-align: center;
      width: 30%;
      img {
        width: 100px;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      h3 {
        color: white;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .products {
    padding: 100px 50px;
    .product-icons {
      .product-icon {
        width: auto;
        img {
          width: 150px;
        }
      }
    }
  }
}

.logo-text {
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 8px;
  white-space: nowrap;
}

footer {
  background: #110f10;
  background-size: cover;
  background-position: center center;
  color: white;
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .footer-section {
      margin-bottom: 30px;
      width: 50%;
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
        &.copyright {
          margin-bottom: 20px;
          font-weight: 900;
        }
      }
      .social {
        margin-top: 20px;
        a {
          display: inline-block;
          margin-right: 10px;
          width: 20px;
          height: auto;
          margin-bottom: 0;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      a {
        display: block;
        color: white;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &:first-of-type,
      &:last-of-type {
        width: 100%;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  footer {
    .wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
      .footer-section {
        margin-right: 20px;
        width: auto;
        order: 1;
        .social {
          margin: 0;
        }
        .copyright {
          margin-top: 0;
        }
        &:first-of-type {
          // max-width: 300px;
          // order: 2;
        }
        &:last-of-type {
          flex-basis: 100%;
          order: 3;
        }
      }
    }
  }
}

//INSTAGRAM

.instagram-section {
  padding: 56px 24px;
  .photos {
    margin-top: 25px;
    img {
      display: none;
      width: 100%;
      &:first-of-type {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .instagram-section {
    padding: 100px 50px;
    h2 {
      float: left;
    }
    .button {
      float: right;
      margin-bottom: 25px;
    }
    .photos {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      clear: both;
      img {
        display: block;
        width: calc(100% / 3 - 20px);
        margin-right: 30px;
        // flex-basis: 0;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

//PRODUCTS PAGE

.products-page-section {
  min-height: 400px;
  .wrapper {
    .carousel {
      padding: 25px;
      .options {
        span {
          display: inline-block;
          padding: 10px;
          font-size: 12px;
          font-weight: 900;
          text-transform: uppercase;
          border-radius: 16px;
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
          margin-right: 10px;
          margin-bottom: 10px;
          border: 2px solid transparent;
          cursor: pointer;
          &:last-of-type {
            margin-right: 0;
          }
          &.active {
            // box-shadow: none;
            background: black;
            color: white;
          }
        }
      }
      .form-info-container {
        .selected-form {
          width: 60%;
          height: auto;
          margin: 25px auto;
        }
        .text-content {
          p:last-of-type {
            margin-top: 15px;
          }
        }
      }
    }
    .product-carousel {
      position: relative;
      .images {
        width: 100%;
        height: 50vh;
        .product-image {
          width: 100%;
          height: 100%;
          display: none;
          object-fit: cover !important;
          object-position: center center !important;
          &.active {
            display: block;
          }
        }
      }
      .controls {
        position: absolute;
        bottom: 25px;
        right: 25px;
        z-index: 2;
        div {
          background: white;
          border-radius: 100%;
          float: left;
          width: 50px;
          height: 50px;
          text-align: center;
          line-height: 50px;
        }
        .forward {
          &:before {
            content: ">";
          }
        }
        .back {
          margin-right: 20px;
          &:before {
            content: "<";
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .products-page-section {
    .wrapper {
      .carousel {
        padding: 25px 50px;
        .options {
          text-align: center;
        }
        .form-info-container {
          display: flex;
          flex-direction: row;
          .selected-form {
            margin-right: 25px;
          }
          .text-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 25px 0px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .products-page-section {
    padding: 50px;
    .wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .product-carousel {
        min-width: 60%;
        margin: 0;
        .images {
          height: 100%;
        }
      }
      .carousel {
        padding: 0;
        padding-right: 25px;
        min-width: 25%;
        .options {
          text-align: left;
        }
        .form-info-container {
          display: block;
          .selected-form {
            margin: 25px auto;
            width: 100%;
          }
          .text-content {
            padding: 0;
          }
        }
      }
    }
  }
}

.simple-section {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .simple-section {
    padding: 100px 50px;
    .wrapper {
      display: flex;
      flex-direction: row;
      h2 {
        margin-right: 10%;
      }
    }
  }
}

//PRISMIC PAGES

.simple-page {
  padding: 25px;

  p,
  li,
  a {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    color: black;
    letter-spacing: normal;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 600px) {
  .simple-page {
    padding: 50px;
  }
}

.map-wrapper {
  overflow: auto;
  background-size: cover;
  .interactive-map {
    width: 100%;
    display: block;
    position: relative !important;
    display: none;
  }
  .locations-list-wrapper {
    display: block;
    height: calc(100vh - 73px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .locations-list-title {
      margin: 0;
      margin-top: 10px;
      padding: 0px 20px;
      color: $wonder-gray;
    }
    .pagination-nav {
      padding: 8px 20px;
      display: flex;
      justify-content: space-between;
      background: $wonder-gray;
      color: white;
      .page-controls {
        span {
          cursor: pointer;
          &:nth-of-type(1) {
            margin-right: 10px;
          }
        }
      }
    }
    .info-message {
      background: $wonder-darkGray;
      color: white;
      padding: 8px 20px;
    }
    .locations-search-form {
      padding: 10px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .locations-list {
      background: white;
      overflow: scroll;
      height: 100%;
      position: relative;
      .single-location {
        padding: 20px;
        overflow: auto;
        border-bottom: 1px solid #000000;
        display: flex;
        flex-direction: row;
        &:last-of-type {
          border-bottom: 0;
        }
        &.active {
          background: rgba(0, 0, 0, 0.2);
        }

        .store-information {
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid #000000;
          width: 100%;
          color: $wonder-gray;
          .store-name {
            font-size: 16px;
            line-height: 1.2;
            margin-bottom: 5px;
            color: $wonder-darkGray;
          }
          .store-address {
            font-size: 14px;
            line-height: 1.2;
            margin-bottom: 10px;
          }
          .distance {
            font-size: 14px;
            line-height: 1.2;
          }
          .usage-type {
            font-size: 12px;
            font-weight: 900;
            margin-bottom: 0;
          }
        }
        .store-actions {
          min-width: 110px;
          .icon-button {
            color: $wonder-darkGray;
            font-size: 10px;
            display: block;
            text-decoration: none;
            font-weight: 900;
            margin-bottom: 10px;
            white-space: nowrap;
            svg {
              display: inline-block;
              vertical-align: middle;
              margin-right: 5px;
              fill: $wonder-darkGray;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .map-wrapper {
    overflow: auto;
    .interactive-map {
      height: calc(100vh - 70px) !important;
      width: 60% !important;
      float: right;
      display: block;
    }
    .stores-wrapper {
      height: calc(100vh - 70px) !important;
      width: 40%;
      float: left;
    }
  }
}

.gm-style {
  font: inherit !important;
  .gm-style-iw-c {
    border-radius: 0 !important;
  }
}

.info-window-content {
  .store-name {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 5px;
    color: $wonder-darkGray;
  }
  .store-address {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .usage-type {
    font-size: 16px;
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
  }
}

#alert-dialog-description {
  p {
    a {
      color: black;
      text-decoration: underline;
    }
  }
}

.no-flick {
  min-height: 100vh;
}

.animation {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  &.yas {
    opacity: 1;
    .hero-section {
      .background-image,
      .background-video,
      .hero-carousel {
        transform: scale(1);
      }
      .text-content {
        opacity: 1;
        transform: translateY(0);
      }
      .product-slides-container {
        &:hover {
          img {
            transform: scale(0.9) !important;
          }
        }
        img {
          transform: translateX(0);
          // opacity: 1;
          &:nth-of-type(1) {
            transform: scale(0.9);
          }
          &:nth-of-type(3) {
            transform: scale(1.2);
          }
          &:nth-of-type(5) {
            transform: scale(0.9);
          }
          &:hover {
            transform: scale(1.2) !important;
          }
        }
      }
    }
  }
}

.map-info-window {
  .button {
    margin-top: 10px;
  }
}

.featured-section {
  .wrapper {
    .featured-item {
      margin: 0 auto;
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .image {
        border-radius: 10px;
        border: 3px solid white;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
      .content {
        padding: 20px;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .featured-section {
    .wrapper {
      max-width: 900px;
      .featured-item {
        margin-bottom: 0 !important;
        max-width: calc(50% - 25px);
        .image {
          margin: 0 auto;
        }
        &:first-of-type {
          margin-right: 25px;
        }
        &:last-of-type {
          margin-left: 25px;
        }
        .content {
          padding: 20px 0px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .featured-section {
    // padding: 96px;
    .wrapper {
      .featured-item {
        max-width: calc(50% - 24px);
        &:first-of-type {
          margin-right: 24px;
        }
        &:last-of-type {
          margin-left: 24px;
        }
      }
    }
  }
}

.text-section {
  background-size: cover !important;
  background-position: center center !important;
  .wrapper {
    * {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    p {
      margin: 20px 0px;
    }
    .image-wrapper {
      margin-top: 40px;
      img {
        max-width: 80%;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .text-section {
    padding: 114px 48px;
  }
}

@media only screen and (min-width: 768px) {
  .flex-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    &.lead-image {
      .image-wrapper {
        order: -1;
      }
    }
  }
}
.product-section {
  .wrapper {
    overflow: visible;
    .button-wrapper {
      margin: 24px 0px;
    }
    .products-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .product {
        width: 100%;
        max-width: 150px;
        &.extra-padding {
          margin: 0;
          padding: 40px;
          max-width: 300px;
        }
        &.solid-background {
          .rich-text {
            p,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: white !important;
            }
          }
        }
        a {
          &:hover {
            img {
              transform: scale(0.9);
            }
          }
          text-align: center;
        }
        img {
          width: 100%;
          display: block;
          max-width: 250px;
          height: 250px;
          object-position: center center;
          object-fit: contain;
          margin: 0 auto;
          transition: all 100ms ease-in-out;
          margin-bottom: 20px;
        }
        .content {
          margin-top: 20px;
        }
        .rich-text {
          width: 100%;
        }
      }
      &.animate {
        .product {
          transform: translateY(100px);
          transition: all 600ms ease-in-out;
          opacity: 0;
          &:nth-of-type(1) {
            transition-delay: 0;
          }
          &:nth-of-type(2) {
            transition-delay: 100ms !important;
          }
          &:nth-of-type(3) {
            transition-delay: 200ms !important;
          }
          &:nth-of-type(4) {
            transition-delay: 200ms !important;
          }
          &:nth-of-type(5) {
            transition-delay: 400ms !important;
          }
        }
        &.animation-active {
          .product {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .product-section {
    .wrapper {
      .content-wrapper {
        margin-right: 30px;
        margin-bottom: 50px;
      }
      .button-wrapper {
        // margin: 0;
      }
      .flex-wrapper {
        align-items: flex-start;
      }
      .products-wrapper {
        justify-content: center;
        .product {
          margin: 0px 20px;
        }
      }
    }
  }
}

.card-swipe-section {
  &.standard-padding {
    padding-bottom: 35px;
  }
  .button-wrapper {
    margin: 24px 0px;
  }
  .products-wrapper {
    display: flex;
    overflow: scroll;
    padding-bottom: 15px;
    .card {
      min-width: 230px;
      min-height: 230px;
      margin-right: 16px;
      position: relative;
      border-radius: 10px;
      border: 3px solid white;
      overflow: hidden;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        .content {
          opacity: 1;
        }
      }
      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          object-position: center center;
          transition: all 300ms ease-in-out;
        }
      }
      .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        opacity: 0;
        background: rgba(0, 0, 0, 0.25);
        transition: all 300ms ease-in-out;
        h2 {
          margin-bottom: 10px;
        }
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .card-swipe-section {
    .wrapper {
      .flex-wrapper {
        .flex-child {
          &:nth-of-type(1) {
            margin-right: 48px;
            max-width: 50%;
          }
          &:nth-of-type(2) {
            width: 100%;
            width: 50%;
          }
          .products-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            column-gap: 24px;
            row-gap: 24px;
            padding: 0;
            .card {
              margin: 0;
              min-width: auto;
              min-height: auto;
              .image {
                position: static;
              }
            }
          }
        }
      }
    }
  }
}

.text-image-section {
  .wrapper {
    max-width: 1300px;
    .content-wrapper {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
    }
    .button-wrapper {
      margin-top: 24px;
      .button {
        min-width: 210px;
        text-align: center;
      }
    }
    .image-wrapper {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    &.full-width {
      .flex-wrapper {
        .content-wrapper .content-wrapper-inner {
          .section1 {
            margin-bottom: 50px;
            @media only screen and (min-width: 768px) {
              margin-bottom: 70px;
            }
          }
        }
        .image-wrapper {
          img {
            object-fit: contain;
            max-height: 30vh;
          }
        }
      }
    }
  }
  &.animate {
    .wrapper {
      .content-wrapper {
        transform: translateX(100px) scale(0.9);
        opacity: 0;
        transition: all 400ms ease-in;
      }
      .image-wrapper {
        transform: translateX(-100px) scale(0.9);
        opacity: 0;
        transition: all 400ms ease-in;
      }
      .lead-image {
        .content-wrapper {
          transform: translateX(-100px);
        }
        .image-wrapper {
          transform: translateX(100px);
        }
      }
    }
    &.animation-active {
      .wrapper {
        .content-wrapper,
        .image-wrapper {
          transform: translateX(0) scale(1);
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .text-image-section {
    .wrapper {
      .image-wrapper {
        min-width: 50%;
        max-width: 50%;
        margin-left: 0;
        margin-top: 0;
        max-width: none;
        img {
          margin: 40px 0px;
        }
      }
      &.full-width {
        max-width: none !important;
        .flex-wrapper {
          .image-wrapper {
            margin-left: 0;
            margin-top: 0;
            max-width: none;
            align-self: flex-end;
            img {
              margin: 0 !important;
              max-height: 50vh;
            }
          }
        }
        .content-wrapper {
          .content-wrapper-inner {
            max-width: 550px;
            width: 100%;
            float: right;
          }
        }
      }
      .content-wrapper {
        text-align: inherit;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .button-wrapper {
    .button {
      width: auto !important;
    }
  }
}

.forms-section {
  transition: all 500ms ease-in-out;
  overflow: hidden;
  .wrapper {
    .flex-wrapper {
      align-items: flex-start;
      .content-wrapper {
        margin-bottom: 15px;
        z-index: 1;
        .text-content {
          margin-bottom: 20px;
        }
        .pills {
          display: flex;
          overflow: scroll;
          padding-bottom: 15px;
          .button {
            margin-right: 8px;
            cursor: pointer;
            &.active {
              background: $new-yellow !important;
              color: black !important;
              border: 2px solid $new-yellow !important;
            }
          }
        }
      }
      .image-wrapper {
        display: flex;
        flex-direction: column;
        .content {
          margin-top: 30px;
        }
        .form-images {
          position: relative;
          height: 50vh;
          z-index: 0;
          order: -1;
          .form-image {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            bottom: 0;
            left: 0;
            transform: translateX(50%);
            transition: all 300ms ease-in-out;
            box-sizing: border-box;
            &.visible {
              opacity: 1;
              transform: translateX(0);
            }
            img {
              height: 100%;
              width: 100%;
              max-width: 400px;
              object-fit: contain;
              object-position: center center;
              transition: all 500ms ease-in-out;
            }
          }
        }
      }
    }

    .form-content {
      display: none;
      &.visible {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .forms-section {
    .wrapper {
      .flex-wrapper {
        min-height: 70vh;
        align-items: stretch;
        flex-direction: column;
        justify-content: unset;
        .content-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .text-content {
            padding-right: 20px;
            width: auto;
            h2 {
              margin: 0;
            }
          }
          .pills {
            display: block;
            overflow: unset;
            width: auto;
            padding-bottom: 0;
            text-align: right;
            .button {
              margin-bottom: 8px;
              &:last-of-type {
              }
            }
          }
        }
        .image-wrapper {
          min-width: 50%;
          height: auto;
          flex-direction: row;
          .content {
            width: 50%;
            margin-top: 0;
            align-self: center;
          }
          .form-images {
            width: 50%;
            order: 2;
            .form-image {
              padding: 0;
              transform: translateY(50%);
              &.visible {
                transform: translateY(0);
              }
              img {
                max-width: none;
                object-position: center right;
              }
            }
            &.lead-image {
              order: -1;
              img {
                object-position: center left;
              }
            }
          }
        }
      }
    }
  }
}

.standard-padding {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .standard-padding {
    padding: 50px;
  }
}

.faq-section {
  .question {
    cursor: pointer;
  }
  table {
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 !important;
    }
    div {
      margin: 0;
    }
    .answer {
      padding: 20px;
    }
  }
}

.full-screen-video-section {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    object-position: center center;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .logo-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    align-items: center;
    justify-content: center;
    .video-logo {
      img {
        width: 100%;
        max-width: 450px;
        margin-bottom: 50px;
        padding: 0px 20px;
        box-sizing: border-box;
      }
    }
    .content {
      color: white;
      a {
        text-decoration: underline;
      }
    }
  }

  .disclaimers {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    color: white;
    text-align: center;
    a {
      text-decoration: underline !important;
    }
  }
}

.box-padding {
  padding: 30px;
}

.animate {
  transform: translateY(100px) scale(0.9);
  transition: all 400ms ease-in;
  opacity: 0;
  &.animation-active {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}
.custom-animation {
  transform: none;
  transition: none;
  opacity: 1;
}

.photo-section {
  margin-bottom: 12px;
  .photo-section-item {
    height: 100%;
    margin-bottom: 12px;
    .image-wrapper {
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
    .content {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .product-image {
        height: 100%;
        // height: auto;
        width: 50px;
        margin-right: 50px;
        object-position: center center;
        object-fit: contain;
      }
      .text-content {
      }
    }
    &.animate {
      .image-wrapper,
      .content {
        // opacity: 0;
        // transform: scale(1.2);
        transition: all 400ms ease-in-out;
      }
      .image-wrapper {
        // transition-delay: 300ms;
        transform: translateX(100px);
      }
      .content {
        transform: translateX(-100px);
      }
      &.animation-active {
        .image-wrapper,
        .content {
          transform: translateX(0) !important;
        }
      }
      &:nth-of-type(even) {
        .image-wrapper {
          // transition-delay: 300ms;
          transform: translateX(-100px);
        }
        .content {
          transform: translateX(100px);
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .photo-section {
    padding: 50px;
    .photo-section-item {
      margin-bottom: 40px;
      .image-wrapper {
        order: 2;
      }
      .content {
        margin: 40px 0px;
        text-align: inherit;
        padding: 50px;
        height: calc(100% - 80px);
      }
      &.lead-image {
        .image-wrapper {
          order: -1;
        }
      }
    }
  }
}

.moods-section {
  margin-bottom: 40px;
  .buttons {
    margin-top: 20px;
  }
  .moods-wrapper {
    .mood-item {
      position: relative;
      margin-bottom: 12px;
      overflow: hidden;
      background-position: center center !important;
      background-size: cover !important;
      &:last-of-type {
        margin-bottom: 0;
      }
      &.coming-soon {
        &:before {
          content: "coming soon";
          position: absolute;
          top: 13px;
          left: -50px;
          color: $wonder-gray;
          background: white;
          padding: 5px 60px;
          font-size: 10px;
          transform: rotate(-25deg);
        }
      }
      .buttons {
        .button {
          margin-left: 0;
          margin-right: 0;
          white-space: pre-wrap !important;
          text-align: center;
          font-size: 13px !important;
          padding: 10px 20px !important;
          letter-spacing: 1px;
          min-width: 190px;
        }
      }
      .toggle {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;
        z-index: 10;
        text-align: center;
        cursor: pointer;
        .toggle-icon {
          font-size: 20px;
          line-height: 40px;
          transition: 300ms ease-in-out;
          &.open {
            transform: rotate(180deg);
          }
        }
        .toggle-text {
          line-height: 10px;
          font-size: 13px;
        }
      }
      .lifestyle-image {
        display: none;
        background-size: cover !important;
        background-position: center center !important;
      }
      .content {
        box-sizing: border-box;
        width: 100%;
        padding: 40px;
        -webkit-user-select: none;
        position: relative;
        overflow: hidden;

        .text-content {
          margin: 40px 0px;
          width: 70%;
          h2,
          h3,
          h4,
          h5 {
            font-family: "Domain Display";
            text-transform: lowercase;
            font-size: 40px;
            letter-spacing: 4px;
          }
          p {
            font-size: 15px;
          }
          h6 {
            color: white;
          }
          .text-content-2 {
            padding: 20px 0px;
          }
        }
        .product-image {
          width: 30%;
          height: 100%;
          margin-left: 40px;
          position: absolute;
          top: 40px;
          right: 20px;
          img {
            width: 100%;
            height: 50vh;
            object-fit: contain;
            object-position: center center;
          }
        }
      }
      &:nth-of-type(even) {
        .content {
          .product-image {
            left: 20px;
            right: auto;
            margin-left: 0;
            margin-right: 40px;
          }
          .text-content {
            float: right;
          }
        }
      }
      &.animate {
        .product-image {
          transform: translateY(200px);
          transition: all 600ms ease-in-out;
          transition-delay: 200ms;
          opacity: 0;
        }
        .text-content {
          transform: translateY(100px);
          opacity: 0;
          transition: all 500ms ease-in-out;
        }
        &.animation-active {
          .product-image {
            transform: translateY(0);
            opacity: 1;
          }
          .text-content {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .moods-section {
    .moods-wrapper {
      padding-bottom: 50px;

      .mood-item {
        margin-bottom: 20px;
        display: flex;
        min-height: 300px;
        .lifestyle-image {
          min-width: 45%;
          display: block;
          order: 1;
          z-index: 2;
          img {
            height: 100%;
          }
        }
        .content {
          min-width: 55%;
          padding: 0% 10%;
        }
        &:nth-of-type(odd) {
          .lifestyle-image {
            order: -1;
          }
          &.coming-soon {
            &:before {
              left: auto;
              right: -50px;
              transform: rotate(25deg);
            }
          }
        }
      }
    }
  }
}

.product-gallery-section {
  .wrapper {
    .text-content {
      text-align: center;
      h3 {
        color: $wonder-red;
      }
      .buttons {
        margin-top: 40px;
      }
      &.animate {
        transform: translateX(-100px) scale(0.9);
        opacity: 0;
        transition: all 400ms ease-in-out;
        &.animation-active {
          transform: translateX(0) scale(1);
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (min-width: 960px) {
  .product-gallery-section {
    .wrapper {
      .hero-carousel {
        margin-bottom: 0;
        margin: 40px;
        background: white;
      }
      .text-content {
        align-self: center;
        text-align: inherit;
      }
    }
  }
}

.pdf-section {
  .react-pdf__Document {
    pointer-events: none;
    -webkit-user-select: none;
    overflow: hidden;
  }
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
  .pdf-navigation {
    text-align: center;
    z-index: 20;
    position: relative;
    .page-indicator {
      margin-bottom: 10px;
    }
    .button:first-of-type {
      margin-right: 10px;
    }
    .button {
      z-index: 20;
    }
  }
}

.MuiMenuItem-root {
  font-family: "Averta Bold" !important;
  color: $wonder-gray !important;
}

.column-section {
  .columns {
    .column {
      padding: 20px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      background-position: center center;
      background-size: cover;
      .foreground-image {
        img {
          width: 100%;
        }
      }
      .buttons {
        text-align: center;
      }
      h2 {
        font-family: "Domain Display";
        text-transform: lowercase;
        font-size: 40px;
        letter-spacing: 4px;
        margin-bottom: 0;
      }
    }
  }
}

.sl-iframe-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 3915px;
}
@media screen and (min-width: 600px) {
  .sl-iframe-container {
    width: 100%;
    height: 4000px;
  }
}
@media screen and (min-width: 768px) {
  .sl-iframe-container {
    width: 100%;
    height: 2760px;
  }
}
.sl-iframe-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  left: 0;
  top: 0;
}

.microcast-slice {
  background-position: center center !important;
  background-size: cover !important;
  .wrapper {
    .content {
      padding: 20px;
      padding-bottom: 300px;
      text-align: center;
      color: white;
      .special-font {
        font-style: italic;
        font-size: 32px;
      }
    }
    .button-container {
      margin-top: 20px;
      text-align: center;
      .special-button {
        display: inline-block;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0px;
        }
        img {
          width: 100px;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .microcast-slice {
    .wrapper {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      .content {
        width: 50%;
        text-align: left;
        padding: 0px;
        padding: 150px 0px;
      }
      .button-container {
        text-align: left;
        .special-button {
          img {
            width: 150px;
          }
        }
      }
    }
  }
}

#contact-page {
  margin-top: 70px;
  .wrapper {
    max-width: 700px;
    padding: 20px;
    margin: 2rem auto;
  }
  @media screen and (min-width: 768px) {
    margin-top: 100px;
  }
}

#contact-header {
  background: $wonder-darkGray;

  h1 {
    text-align: center;
    padding: 80px 0px;
    color: white;
    margin: 0;
  }
}

.form-message {
  display: block;
  background: $wonder-gray;
  color: white;
  padding: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
  &.error {
    color: black;
    border: 1px solid $errorRed;
    background: white;
    color: $errorRed;
  }
}

.underline {
  text-decoration: underline;
  font-weight: bold;
}

.MuiFormLabel-root.Mui-error {
  background: none !important;
  color: $errorRed !important;
  display: inline-block !important;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: $errorRed !important;
}

.accessibility {
  display: inline-block;
  position: relative;
  bottom: 6px;
  margin-left: 10px;
  .MuiTypography-root {
    color: white;
  }
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
  white-space: nowrap;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}
