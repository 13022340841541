.faq-popover {
  .MuiPopover-paper {
    bottom: 20px;
    right: 20px;
  }
}
.shop-page-dialog {
  .MuiPaper-rounded {
    border-radius: 0px !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 850px !important;
  }

  .MuiBackdrop-root {
    background: none !important;
  }
}

.dialog-wrapper {
  position: relative;
  text-align: center;
  padding: 20px;
  text-align: center;
  background: $wonder-gray;
  color: white;

  .close {
    position: absolute;
    bottom: 48px;
    right: 16px;
    color: black;
    button {
      padding: 0;
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    .sunny-logo,
    .jane-logo {
      margin: 0 auto 25px;
      display: block;
      border-radius: 10px;
    }
    .sunny-logo {
      width: 150px;
    }
    .jane-logo {
      width: 86px;
    }
    .shop-sunny {
      border-bottom: 1px solid white;
      padding-bottom: 35px;
      margin-bottom: 32px;
    }

    a {
      text-decoration: none;
      button {
        font-size: 12px;
        background: none;
        color: $wonder-gray;
        background: white;
        transition: 200ms ease;
        &:hover {
          background: $wonder-darkGray;
          color: white;
        }
      }
    }
  }
  .logo-container {
    margin-bottom: 40px;
    padding: 15px;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    svg,
    img {
      max-width: 120px;
      margin: auto;
    }
  }
  .content {
    margin: 95px auto 10px;
    max-width: 450px;
    p {
      font-size: 16px;
    }
  }
  .disclaimer-text {
    p:first-of-type {
      margin: 35px auto 0 auto;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      max-width: 500px;
      margin: 20px auto 0 auto;
      a {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .dialog-wrapper {
    .button-container {
      flex-direction: row;
      margin-top: 30px;
      .sunny-logo,
      .jane-logo {
        display: block;
      }
      .sunny-logo {
        width: 198px;
        margin-bottom: 35px;
      }
      .jane-logo {
        width: 104px;
      }
      .shop-sunny {
        border-right: 1px solid white;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
        padding: 15px 35px 0 35px;
      }
      .shop-jane {
        padding: 15px 35px 0 35px;
      }

      a {
        button {
          font-size: 16px;
        }
      }
    }
    .logo-container {
      margin-bottom: 20px;
      svg {
        max-width: 160px;
      }
      .close {
        bottom: 44px;
      }
    }
    .content {
      margin-bottom: 50px;
      margin-top: 110px;
      p {
        font-size: 20px;
      }
    }
    .disclaimer-text {
      margin-top: 50px;
    }
  }
}
